import React from "react"
import Helmet from "react-helmet"
import HeaderImage from "../images/714OG.jpg"
import TwitterImage from "../images/714Twitter.jpg"

const PodcastHead = () => (
  <Helmet>
    {/* <!-- Primary Meta Tags --> */}
    <title>The 7:14 Devo Podcast</title>
    <meta name="title" content="The 7:14 Devo Podcast" />
    <meta name="description" content="A podcast based on 2 Chronicles 7:14." />
    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content="https://www.crosschurchcares.com/podcast"
    />
    <meta property="og:title" content="The 7:14 Devo Podcast" />
    <meta
      property="og:description"
      content="A podcast based on 2 Chronicles 7:14."
    />
    <meta property="og:image" content={HeaderImage} />
    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta
      property="twitter:url"
      content="https://www.crosschurchcares.com/podcast"
    />
    <meta property="twitter:title" content="The 7:14 Devo Podcast" />
    <meta
      property="twitter:description"
      content="A podcast based on 2 Chronicles 7:14."
    />
    <meta property="twitter:image" content={TwitterImage} />
  </Helmet>
)

export default PodcastHead
